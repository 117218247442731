@tailwind base;
@tailwind components;
@tailwind utilities;


.scrollbar::-webkit-scrollbar{
    width: 5px;
    height: 5px;
    z-index: -1;
  
  }
  
  .scrollbar::-webkit-scrollbar-track{
   box-shadow: inset 0 0 6px rgba(0,0,0,0.25);
   z-index: -1;
  }
  
  .scrollbar::-webkit-scrollbar-thumb{
    background-color: #808080;
    border-radius: 2.5px;
    z-index: -1;
  }


  .scrollbar-lg::-webkit-scrollbar{
    width: 10px;
    z-index: -1;
  
  }
  
  .scrollbar-lg::-webkit-scrollbar-track{
   box-shadow: inset 0 0 6px rgba(0,0,0,0.25);
   z-index: -1;
  }
  
  .scrollbar-lg::-webkit-scrollbar-thumb{
    background-color: #808080;
    border-radius: 2.5px;
    z-index: -1;
  }
